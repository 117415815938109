
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  on: {
    pageMounted: function (e, page) {
      var self = this;
      var app = self.$app;
      var videos = document.querySelectorAll('video');

      if (app.device.android) {
        $$('video').css('display', 'block');
        videos.forEach(function (v) {
          v.play();
        });
      } // var splashpopup = app.popup.create({
      //   el: '#splash-home',
      //   swipeToClose: true,
      // });
      // splashpopup.open();

    },
    pageInit: function (e, page) {},
    pageBeforeIn: function (e, page) {// Get all videos.
    },
    pageAfterIn: function (e, page) {
      var self = this;
      var app = self.$app; //         if(app.device.ios){
      //           console.log('ios');
      //           var dynamicSheet = app.sheet.create({
      //   content: '<div class="sheet-modal install-modal my-sheet-swipe-to-close" style="height:auto; --f7-sheet-bg-color: #fff;"> <div class="sheet-modal-inner"> <div class="page-content"> <div class="block-title block-title-large"></div> <div class="block"> <div class="button">DESCARGA EL APP HACIENDO CLICK AQUÍ</div><div class="block"></div> </div> </div> </div> </div>',
      //   // Events
      //   on: {
      //     open: function (sheet) {
      //       console.log('Sheet open');
      //     },
      //     opened: function (sheet) {
      //       console.log('Sheet opened');
      //     },
      //   }
      // });
      //           // dynamicSheet.open();
      //         }
      // $$('#splash-home').on('popup:swipeend', function (e) {
      //   videos.forEach(function(v) {
      //     v.play();
      //   });
      // });

      $$('.card-expandable').on('card:open', x => {
        let swiper = app.swiper.get('.swiper-container');
        swiper.allowTouchMove = false;
      });
      $$('.card-expandable').on('card:close', x => {
        let swiper = app.swiper.get('.swiper-container');
        swiper.allowTouchMove = true;
      });
      $$('.tabbar .tab-link').on('click', function (event) {
        event.preventDefault();
        let swiper = app.swiper.get('.swiper-container');
        app.card.open($$(this).attr('href'));
      });
    },
    pageBeforeOut: function (e, page) {},
    pageAfterOut: function (e, page) {},
    pageBeforeRemove: function (e, page) {}
  },
  id: 'a0036c283f',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page id=home data-name=home><!-- bottom Navbar --><!-- Additional "tabbar-labels" class --><div class="toolbar tabbar tabbar-labels toolbar-bottom"><div class=toolbar-inner><a href=#card-1 data-index=1 class=tab-link><img src=https://donar.amigosdelasierra.org/administrador/public/uploads/amigos-sierra/originals/01adc88e-e2e8-4f73-90fc-9eb69b701784.svg alt="" class=icon> <span class=tabbar-label>Incendios</span> </a><a href=#card-2 data-index=2 class=tab-link><img src=https://donar.amigosdelasierra.org/administrador/public/uploads/amigos-sierra/originals/019184bd-dac9-48e1-ace2-c4a6e430b08d.svg alt="" class=icon> <span class=tabbar-label>Reforestación</span> </a><a href=#card-3 data-index=3 class=tab-link><img src=https://donar.amigosdelasierra.org/administrador/public/uploads/amigos-sierra/originals/b28cf0eb-8f5a-454f-be5e-dbd57756395b.svg alt="" class=icon> <span class=tabbar-label>Educación</span> </a><a href=#card-4 data-index=4 class=tab-link><img src=https://donar.amigosdelasierra.org/administrador/public/uploads/amigos-sierra/originals/15f985ad-49f6-4c7e-9012-a76a2b99e0b9.svg alt="" class=icon> <span class=tabbar-label>Sanidad</span></a></div></div><!-- Scrollable page content--><div class=page-content><div data-pagination=\'{"el": ".swiper-pagination"}\' data-space-between=10 data-slides-per-view=1.2 data-centered-slides=true class="swiper-container swiper-init demo-swiper demo-swiper-auto"><div class=swiper-wrapper><div class=swiper-slide><div class="card card-expandable"><div class=fullscreen-bg><video playsinline autoplay muted loop><source src="" type=video/mp4></video></div><div class=card-content><div class=card-small style="background-image: url(';
      r += c(ctx_1.informacion.cover.data.full_url, ctx_1);
      r += ');"><a href=# class="link card-close card-opened-fade-in" style="position: absolute; right: 30px; top: 30px"><!-- <i class="icon f7-icons"></i> --> <i class=f7-icons>multiply_circle_fill</i></a><div class=pleca-card><img src=/static/bg-card.svg width=100% height=auto alt=""></div></div><div class=card-content-padding><p class=block-title><img src=';
      r += c(ctx_1.informacion.icono.data.full_url, ctx_1);
      r += ' alt=""></p><p class=block-title style="margin-bottom: 25px;">';
      r += c(ctx_1.informacion.titulo, ctx_1);
      r += '</p><div class=block><div class=row><div class=col><p class=descripcion>';
      r += c(ctx_1.informacion.descripcion, ctx_1);
      r += '</p><p class=descripcion><b>Información de contacto:</b></p><p class=descripcion><a href=';
      r += c(ctx_1.informacion.direccion_link, ctx_1);
      r += ' class="link external" target=_blank>';
      r += c(ctx_1.informacion.direccion, ctx_1);
      r += '</a></p><p class=descripcion><a href=tel:';
      r += c(ctx_1.informacion.telefono, ctx_1);
      r += ' class="link external">';
      r += c(ctx_1.informacion.telefono, ctx_1);
      r += '</a></p><p class=social>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.informacion.redes_sociales, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' <a href=';
          r += c(ctx_2.link, ctx_2);
          r += ' alt class="link external icon-only" target=_blank><i class=f7-icons>logo_';
          r += c(ctx_2.texto, ctx_2);
          r += '</i> </a>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</p></div></div><!-- <div class="row no-gap">\n                        <div class="col-20"></div>\n                        <div class="col-60">\n                          <a class="button button-raised  button-fill button-large link" data-force="true" data-ignore-cache="true" data-view=".view-main" href="/campana/';
      r += c(ctx_1.id, ctx_1);
      r += '" style="margin-top: 15px;">Donar</a>\n\n                        </div>\n\n                        <div class="col-20"></div>\n                      </div> --></div></div></div><!-- <div class="card-header text-color-white display-block">\n                </div> --><div class="card-btns card-prevent-open"><div class=pleca-card><img src=/static/bg-card.svg width=100% height=auto alt=""></div><div class="row no-gap"><div class=col-10></div><div class=col-80><div class=row><div class=col-70><p class=campana-title>';
      r += c(ctx_1.informacion.titulo, ctx_1);
      r += '</p></div><div class=col-30><div class=icon><img src=';
      r += c(ctx_1.informacion.icono.data.full_url, ctx_1);
      r += ' width=70px height=auto alt=""></div></div></div></div><div class=col-10></div></div><!-- <div class="row no-gap">\n                    <div class="col-20"></div>\n                    <div class="col-60">\n                      <a class="button button-raised  button-fill button-large link" data-force="true" data-ignore-cache="true" data-view=".view-main" href="/campana/';
      r += c(ctx_1.id, ctx_1);
      r += '">Donar</a>\n\n                    </div>\n\n                    <div class="col-20"></div>\n                  </div> --></div><div class=card-info-btn style="position: absolute; right: 15px; top: 15px"><a href=# class=link><!-- <i class="icon f7-icons"></i> --> <i class=f7-icons style="">info_circle_fill</i></a></div></div></div>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.campanas, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=swiper-slide><div class="card card-expandable" id=card-';
          r += c(ctx_2.id, ctx_2);
          r += '><div class=fullscreen-bg><video playsinline autoplay muted loop><source src=';
          r += c(ctx_2.video.data.full_url, ctx_2);
          r += ' type=video/mp4></video><div class=campana-bg style="background-image: url(';
          r += c(ctx_2.cover.data.full_url, ctx_2);
          r += ');"></div></div><div class=card-content><div class=card-small style="background-image: url(';
          r += c(ctx_2.cover.data.full_url, ctx_2);
          r += ');"><a href=# class="link card-close card-opened-fade-in" style="position: absolute; right: 15px; top: 15px"><!-- <i class="icon f7-icons"></i> --> <i class=f7-icons>multiply_circle_fill</i></a><div class=pleca-card><img src=/static/bg-card.svg width=100% height=auto alt=""></div></div><div class=card-content-padding><p class=block-title><img src=';
          r += c(ctx_2.icono.data.full_url, ctx_2);
          r += ' alt=""></p><p class=block-title style="margin-bottom: 25px;">';
          r += c(ctx_2.nombre, ctx_2);
          r += '</p><div class=block><div class=row><div class=col><p class=descripcion>';
          r += c(ctx_2.descripcion, ctx_2);
          r += '</p></div></div><div class="row no-gap"><div class=col-20></div><div class=col-60><a class="button button-raised button-fill button-large link" data-force=true data-ignore-cache=true data-view=.view-main href=/campana/';
          r += c(ctx_2.id, ctx_2);
          r += ' style="margin-top: 15px;">Donar</a></div><div class=col-20></div></div></div></div></div><!-- <div class="card-header text-color-white display-block">\n                </div> --><div class="card-btns card-prevent-open"><div class=pleca-card><img src=/static/bg-card.svg width=100% height=auto alt=""></div><div class="row no-gap"><div class=col-10></div><div class=col-80><div class=row><div class=col-70><p class=campana-title>';
          r += c(ctx_2.nombre, ctx_2);
          r += '</p></div><div class=col-30><div class=icon><img src=';
          r += c(ctx_2.icono.data.full_url, ctx_2);
          r += ' alt=""></div></div></div></div><div class=col-10></div></div><div class="row no-gap"><div class=col-20></div><div class=col-60><a class="button button-raised button-fill button-large link" data-view=.view-main href=/campana/';
          r += c(ctx_2.id, ctx_2);
          r += '>Donar</a><!-- <a class="button button-raised  button-fill button-large link" data-force="true" data-ignore-cache="true" data-view=".view-main" href="/campana/';
          r += c(ctx_2.id, ctx_2);
          r += '">Donar</a> --></div><div class=col-20></div></div></div><div class=card-info-btn style="position: absolute; right: 15px; top: 15px"><a href=# class=link><!-- <i class="icon f7-icons"></i> --> <i class=f7-icons style="">info_circle_fill</i></a></div></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    