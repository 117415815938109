import $$ from 'dom7';
import Framework7 from 'framework7/framework7.esm.bundle.js';

// Import F7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.styl';
// Import Cordova APIs
import cordovaApp from './cordova-app.js';
// Import Routes
import routes from './routes.js';

// Import main app component
import App from '../app.f7.html';

var app = new Framework7({
  root: '#app', // App root element
  component: App, // App main component
  id: 'io.wetheforce.amigossierra', // App bundle ID
  name: 'Amigos de la Sierra', // App name
  theme: 'auto', // Automatic theme detection
  data: {
    token: 'Basic a2V5XzZ2VjJ6Mkh5SjdOS3hNcWU1cmJlcVE6',
    server: 'https://donar.amigosdelasierra.org/administrador/public/amigos-sierra',
    stripeSK: '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',
    stripePK: 'pk_live_51H8D6zKI7WVVrNICPOgLAmQNiBH5PfRCptJrGIG4DQXYmpQJNv2d39ds2OXYPSRbBh6EDKHxfCozQXPy1YTSB1Qw00gdrJydyy',
    stripeTK: 'sk_test_4eC39HqLyjWDarjtT1zdp7dc',
    stripeUrl: 'https://api.stripe.com/v1/checkout/sessions',
    paymentUrl: 'https://api.stripe.com/v1/payment_intents',
    // api: 'https://wetheforcestudios.com/api/public/carl-donations'
  },

  view: {
    pushState: true
  },


  // App routes
  routes: routes,
  statusbar: {
    iosOverlaysWebView: false,
  },
  // Register service worker
  serviceWorker: Framework7.device.cordova ? {} : {
    path: '/service-worker.js',
  },
  // Input settings
  input: {
    scrollIntoViewOnFocus: Framework7.device.cordova && !Framework7.device.electron,
    scrollIntoViewCentered: Framework7.device.cordova && !Framework7.device.electron,
  },
  // Cordova Statusbar settings
  statusbar: {
    iosOverlaysWebView: false,
    androidOverlaysWebView: false,
  },
  on: {
    init: function () {
      var f7 = this;
      if (f7.device.cordova) {
        // Init cordova APIs (see cordova-app.js)
        cordovaApp.init(f7);
        // StatusBar.hide();
        f7.statusbar.show();
      }
    },
  },
});