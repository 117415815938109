import $$ from 'dom7';
export default {
  // App root data
  data() {
    return {
      foo: 'bar'
    };
  },

  // App root methods
  methods: {
    doSomething() {// ...
    }

  },
  on: {
    pageMounted: function (e, page) {
      var self = this;
      var app = self.$app;

      if (app.device.desktop) {
        console.log('desktop');
        $$('#desktop').css('display', 'block');
      }
    }
  },
  id: '803a2d7317',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div id=app><!-- Your main view, should have "view-main" class --><div class="view view-main view-init safe-areas" data-url=/ ></div><div id=splash-home class="popup popup-swipe-to-close"><div class="block text-align-center"><img src=/static/logo-color.svg alt="" width=70% height=auto></div></div><div id=desktop><div class=block><p style="text-align: center;"><img src=/static/logo-color.svg alt="" class=logo></p></div><div class=block-title><p style="text-align: center; color: white;">Visítanos en tu celular</p></div><div class=block><p style="text-align: center;"><img src=/static/qrcode.png alt="" class=qr></p></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};