
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
import { loadStripe } from '@stripe/stripe-js';
export default {
  on: {
    pageMounted: function (e, page) {
      // const curl = new (require( 'curl-request' ))();
      var self = this;
      var app = self.$app;
      const stripe = Stripe(app.data.stripePK);
      var stripeurl = app.data.stripeUrl;
      app.request.setup({
        headers: {
          'Authorization': 'Bearer ' + app.data.stripeSK,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
      $$('.set-price').on('click', function () {
        app.dialog.prompt('Ingresa la cantidad en enteros', '¿Cuánto quieres donar?', function (price) {
          $$('.set-price .donacion').data('donacion', price);
          $$('.custom-price').html(price);
        });
      });
      $$('#donar').on('click', function (event) {
        var cantidadDonada = $$('.swiper-slide-active .donacion').data('donacion');

        if (cantidadDonada < 25) {
          app.dialog.alert('Ingresa una cantidad mayor a $24.99 por favor!', 'Donación mínima');
        } else {
          app.preloader.show();
          event.preventDefault();
          var donacion = cantidadDonada * 100;
          var campanaName = $$('.page').data('campana');
          var campanaId = $$('.page').data('campanaid');
          app.request.promise.post(stripeurl, {
            success_url: 'https://donar.amigosdelasierra.org/',
            cancel_url: 'https://donar.amigosdelasierra.org/',
            payment_method_types: ['card'],
            line_items: [{
              price_data: {
                currency: 'mxn',
                product_data: {
                  name: 'Amigos de la Sierra - Donación'
                },
                unit_amount: donacion
              },
              quantity: 1
            }],
            mode: 'payment'
          }).then(function (res) {
            var sessionData = JSON.parse(res.data);
            console.log(sessionData.id);
            stripe.redirectToCheckout({
              sessionId: sessionData.id
            });
          });
        }
      });
    },
    pageInit: function (e, page) {},
    pageBeforeIn: function (e, page) {// Get all videos.
    },
    pageAfterIn: function (e, page) {},
    pageBeforeOut: function (e, page) {},
    pageAfterOut: function (e, page) {},
    pageBeforeRemove: function (e, page) {}
  },
  id: 'da1e906c4e',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=campana data-campana=';
      r += c(ctx_1.campana.nombre, ctx_1);
      r += ' data-campanaid=';
      r += c(ctx_1.campana.id, ctx_1);
      r += ' id=campana><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><!-- <i class="icon icon-back"></i> --> <span class=material-icons>close </span><!-- <span class="if-not-md">Back</span> --></a></div><!-- <div class="title">';
      r += c(ctx_1.campana.nombre, ctx_1);
      r += '</div> --></div></div><div class=page-content><div class=logo><img src=/static/logo-color.svg alt=""></div><div class=title>';
      r += c(ctx_1.campana.nombre, ctx_1);
      r += '</div><div data-pagination=\'{"el": ".swiper-pagination"}\' data-space-between=10 data-slides-per-view=1.5 data-centered-slides=true class="swiper-container swiper-init"><div class=swiper-wrapper>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.paquetes, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="swiper-slide paquete">';
          r += Template7Helpers.if.call(ctx_2, ctx_2.icono, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' <img src=';
              r += c(ctx_3.icono.data.full_url, ctx_3);
              r += ' alt="" class=icono> ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += ' <span class="material-icons icono">star </span>';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '<div class=card><div class="card-content card-content-padding"><p class=descripcion>';
          r += c(ctx_2.descripcion, ctx_2);
          r += '</p><p class=nombre>';
          r += c(ctx_2.nombre, ctx_2);
          r += '</p><p class="precio donacion" data-donacion=';
          r += c(ctx_2.cantidad, ctx_2);
          r += '>MXN $';
          r += c(ctx_2.cantidad, ctx_2);
          r += '</p></div></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<div class="swiper-slide paquete"><span class="material-icons icono">star</span><div class="card set-price"><div class="card-content card-content-padding"><p class=descripcion>¿Cuánto?</p><p class=nombre>Tu decides</p><p class="precio personalizado donacion" data-donacion=0>MXN $<span class=custom-price>0</span>.00 <i class=f7-icons>pencil</i></p></div></div></div></div></div><div class="row no-gap"><div class=col-20></div><div class=col-60><a id=donar class="button button-raised button-fill button-large link donar" href="">Donar</a></div><div class=col-20></div></div><div class=footer><img src=/static/footer.svg alt="" width=100% height=auto></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    